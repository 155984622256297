.slick-slider {
  max-width: 62rem;
  margin: 0 auto;
}

.slick-center {
  border: 1px solid #556ab0;
  border-radius: 16px;
  background: #f9f9fc;
}

.slick-center p,
.slick-center span {
  color: #556ab0 !important;
}
.slick-dots {
  bottom: -130px;
}

.slick-dots li {
  height: 100px;
  width: 100px;
}

@media screen and (max-width: 600px) {
  .slick-slider {
    width: 19rem;
  }
  .slick-center {
    font-size: 22px;
  }
  .slick-active p,
  .slick-active span {
    color: #556ab0 !important;
  }
  .slick-active {
    border: 1px solid #556ab0;
    border-radius: 16px;
    background: #f9f9fc;
  }
}
